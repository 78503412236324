<template>
  <nav class="Nav">
    <router-link
      class="Nav-Link"
      v-for="(nav, i) in navMenu"
      :key="i"
      :to="{ name: nav.link, params: { locale: $i18n.locale }}"
      v-show="!((nav.link === 'Career' || nav.link === 'Academy') && $i18n.locale === 'en')"
    >
      {{ nav.name }}
    </router-link>
  </nav>
</template>
<script>
import {scrollTo} from "../assets/utils/utils";

export default {
  name: "MainNav",
  computed: {
    navMenu() {
      return [
        { link: 'Home', name: this.$t('mainNav.home') },
        { link: 'Career', name: this.$t('mainNav.career') },
        { link: 'Contacts', name: this.$t('mainNav.contacts') },
        { link: 'Academy', name: this.$t('mainNav.academy') }
      ]
    }
  },
  methods: {
    scrollTo
  }
}
</script>

<style lang="scss">
.Nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  line-height: 1.24;
  text-transform: uppercase;

  @media (min-width: $screen-l) {
    font-size: 14px;
  }

  &-Link + &-Link {
    margin-top: 22px;
  }

  @media (min-width: $screen-l) {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;

    &-Link + &-Link {
      margin-top: 0;
      margin-left: 40px;
    }
  }

  @media (min-width: $screen-xl) {
    &-Link + &-Link {
      margin-left: 60px;
    }
  }
}
</style>
